import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { notificationAction } from "../../../../redux/reducers/notification_reducer";
import ReactDatePicker from "react-datepicker";
import CaptureImage from "../../../../components/profile/CaptureImage";
import * as ActionTypes from "../../../../redux/actions/types";
import Select from "react-select";
import { connect } from "react-redux";
import HttpService from "../../../../services/HttpService";
import { addStudentImage } from "../../apiCalls.js";
import LoadingMask from "../../../../components/Loader/LoadingMaskDefault";
import { withRouter } from "react-router";
let selectedFile = {};
const innerwidth=window.innerWidth>960;

class StudentForm extends Component {
  state = {
    showModal: false,
    imgData: null,
    person_class: [],
    branch: [],
    section: [],
    appLoadingState: false,

  };
  async getLookUp() {
    this.setState({ person_class: await HttpService.getClassList() });
    this.setState({ branch: await HttpService.getSitesList() });
    this.setState({ section: await HttpService.getSectionsList() });

  }
  componentDidMount() {
    this.getLookUp();

  }
  modeladd = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  handleChangeimage = () => {
    this.setState({
      appLoadingState: true,
    });
    const data = new FormData();
    data.append("image", selectedFile);
    addStudentImage({ data }).then((response) => {
      if (response.code == 200) {
        this.setState({
          appLoadingState: false,
        });
        this.props.onImageAdded(response.data.id);
      } else if (response.code != 200) {
        this.setState({
          appLoadingState: false,
        });
        const info = {
          message: response.message,
          status: "error",
        };
        this.props.notificationAction(info);
      }
    });
  };
  uploadImageToServer = (datavalue) => {
    this.setState({ imgData: datavalue });

    function DataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(",");
      const byteString =
        splitDataURI[0].indexOf("base64") >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);

      return new Blob([ia], { type: mimeString });
    }
    let selectedFile = {};
    selectedFile = DataURIToBlob(datavalue);
    const data = new FormData();
    data.append("image", selectedFile);
    addStudentImage({ data }).then((response) => {
      if (response.code == 200) {
        this.props.onImageAdded(response.data.id);
        const info = {
          message: response.message,
          status: "success",
        };
        this.props.notificationAction(info);
      } else if (response.code != 200) {
        const info = {
          message: response.message,
          status: "error",
        };
        this.props.notificationAction(info);
      }
    });
  };
  onChangePicture = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({ imgData: reader.result });
      });
      reader.readAsDataURL(e.target.files[0]);
    }

    if (e.target.files && e.target.files[0]) {
      selectedFile = e.target.files[0];
    }
    this.handleChangeimage();
  };


  handelGoBack = () => {
    const { history } = this.props;
    history.push("/students/list-of-Students")
  };

  render() {
    const boolRfid = process.env.REACT_APP_RFID;

    const {
      selectedStudentInfo,
      selectedGuardianInfo,
      SelectedValues,
      onFormFieldChange,
      onSave,
      validateStudent,
      onStudentUpdate,
      selectedDOBDate,
      onDOBChange,
      onDDChange,
    } = this.props;
    const {
      first_name,
      middle_name,
      rfid,
      last_name,
      gu_id,
      roll_number,
      avatar,
      email,
      gardian_relation,
      guardian,
      phone_number,
    } = selectedStudentInfo;

    const {
      full_name,
      relation,
      phone,
      address_1,
      address_2
    } = selectedGuardianInfo

    const {
      selected_person_class,
      selected_site,
      selected_section_id
    } = SelectedValues;
    const {
      invalidfirst_name,
      invalidlast_name,
      invalidreg_number,
      invalidemail
    } = validateStudent;
    let template;
    template = (
      <div>
        <div className="content">
          <Row>
            {/* <Col md="4"></Col> */}
            <Col className="ml-3 form-heading" md={3} lg={3}>
            </Col>
            <Col md="12">
              <Card className="card-user">
                <h4 className="p-4 form-heading" >Student Information</h4>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label className="form-label required">
                            {"First Name"}
                          </label>
                          <Input
                            type="text"
                            autoFocus={invalidfirst_name}
                            className={`input-text-field  form-label" ${invalidfirst_name ? "inValid" : ""
                              }`}
                            name="first_name"
                            value={first_name}
                            onChange={onFormFieldChange}
                            maxLength={"20"}

                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        {this.state.imgData || avatar ? (
                          <Row className="p-4">
                            <Col xs={12} sm={6} xl={3} md={4}>
                              <div className="headline-3 mb-4 text-muted">
                                {this.state.appLoadingState ? (
                                  <LoadingMask />
                                ) : null}
                                <img
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    objectFit: "cover",
                                    position: "absolute",
                                    marginLeft:"18%"
                                  }}
                                  className="rounded-circle profileImage"
                                  // src={this.state.imgData}
                                  src={
                                    this.state.imgData
                                      ? this.state.imgData
                                      : avatar
                                  }
                                ></img>
                              </div>
                            </Col>
                            <Col
                              xs={12}
                              sm={6}
                              xl={3}
                              md={4}
                              // style={{ marginLeft: "56px" }}
                              className="upload-image-res"

                            >
                              <div className="mb-3 browse-image-layout ">
                                <div className="headline-3 mb-0">
                                  <label
                                    htmlFor="file-upload"
                                    className="custom-file-upload custom-file-upload4 mr-2"
                                  >
                                    Browse Image
                                  </label>
                                  <Input
                                    id="file-upload"
                                    type="file"
                                    name="image"
                                    onChange={(e) => this.onChangePicture(e)}
                                    maxLength={"20"}

                                  />
                                </div>
                                <div className="full-width-column image-margin-upload">
                                <p className="body-1 mb-4  mt-2 full-width-column">
                                  <Button
                                    // style={{ width:innerwidth ? "12.5rem":"" }}
                                    className="btn-lg button-full-width btn-widths"
                                    color="primary"
                                    onClick={() => this.modeladd()}
                                  >
                                    Capture Image
                                  </Button>
                                </p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <FormGroup>
                            <label className="form-label required">Image</label>
                            <Row className="full-width-column image-margins">
                              <div className="full-width-column image-margin">
                              <label
                                htmlFor="file-upload"
                                className="custom-file-upload custom-file-upload2 mr-2 form-label button-full-width"
                              >
                                Browse Image
                              </label>
                              <input
                                id="file-upload"
                                type="file"
                                onChange={(e) => this.onChangePicture(e)}
                                // accept="image/jpeg"
                                name="image"
                              />
                              </div>
                              <div className="full-width-column image-margin">
                              <Button
                                className="captureImageButton mr-1  button-full-width" 
                                color="primary"
                                onClick={() => this.modeladd()}
                              >
                                Capture Image
                              </Button>
                              </div>
                            </Row>
                          </FormGroup>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label className="form-label">Middle Name</label>
                          <Input
                            type="text"
                            autoFocus={"off"}
                            className={"input-text-field  form-label"}
                            name="middle_name"
                            value={middle_name}

                            onChange={onFormFieldChange}
                            maxLength={"20"}

                          />
                        </FormGroup>
                      </Col>
                      {boolRfid && boolRfid === "true" ?
                      <Col className="pr-1" md="6">
                      <FormGroup>
                          <label className="form-label">RFID</label>
                          <Input
                            type="text"
                            autoFocus={"off"}
                            className={"input-text-field  form-label"}
                            name="rfid"
                            value={rfid}

                            onChange={onFormFieldChange}
                            maxLength={"30"}

                          />
                        </FormGroup>
                      </Col> : null}
                    </Row>
                    <Row className=" mb-3">
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label className="form-label required">
                            {"Last Name"}
                          </label>
                          <Input
                            type="text"
                            autoFocus={invalidlast_name}
                            className={`input-text-field ${invalidlast_name ? "inValid" : ""
                              }`}
                            name="last_name"
                            value={last_name}
                            onChange={onFormFieldChange}
                            maxLength={"20"}

                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label className="form-label required">
                            {"Registration #"}
                          </label>
                          <Input
                            type="text"
                            autoFocus={invalidreg_number}
                            className={`input-text-field ${invalidreg_number ? "inValid" : ""
                              }`}
                            name="reg_number"
                            value={gu_id}
                            onChange={onFormFieldChange}
                            maxLength={"20"}

                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <label className="form-label">
                          {"Roll #"}
                        </label>
                        <Input
                          type="text"
                          autoComplete="off"
                          name="roll_number"
                          className={`input-text-field`}
                          value={roll_number}
                          onChange={onFormFieldChange}
                          maxLength={"20"}
                        />
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label className="form-label">{"Class"}</label>
                          <Select
                            isSearchable={false}
                            isClearable={true}
                            name="person_class"
                            className={"select-input "}
                            placeholder={""}
                            value={selected_person_class}
                            onChange={(selectedOption, name) =>
                              onDDChange(selectedOption, name)
                            }
                            options={this.state.person_class}
                            classNamePrefix="react-select"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>

                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label className="form-label">{"Section"}</label>
                          <Select
                            isSearchable={false}
                            isClearable={true}
                            name="section_id"
                            className={"select-input "}
                            placeholder={"Select a Section"}
                            value={selected_section_id}
                            onChange={(selectedOption, name) =>
                              onDDChange(selectedOption, name)
                            }
                            options={this.state.section}
                            classNamePrefix="react-select"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label className="form-label">{"Branch"}</label>
                          <Select
                            isSearchable={false}
                            isClearable={true}
                            name="site"
                            className={"select-input "}
                            placeholder={"Select a Branch"}
                            value={selected_site}
                            onChange={(selectedOption, name) =>
                              onDDChange(selectedOption, name)
                            }
                            options={this.state.branch}
                            classNamePrefix="react-select"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <label className="form-label">
                          {"Date of Birth"}
                        </label>
                        <ReactDatePicker
                          selected={selectedDOBDate}
                          onChange={onDOBChange}
                          todayButton="Today"
                          maxDate={new Date()}
                          value={selectedDOBDate && selectedDOBDate}
                          dateFormat="yyyy-MM-dd"
                        />
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label className="form-label">Email</label>
                          <Input
                            type="text"
                            autoFocus={invalidemail}
                            className={`input-text-field ${invalidemail ? "inValid" : ""
                          }`}                            
                          name="email"
                            value={email}
                            onChange={onFormFieldChange}
                            maxLength={"64"}

                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>

                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label className="form-label">Phone Number</label>
                          <Input
                            type="text"
                            autoFocus={"off"}
                            className={"input-text-field  form-label"}
                            name="phone_number"
                            value={phone_number}
                            onChange={onFormFieldChange}
                            maxLength={"20"}

                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1 mt-2 mb-2" md="6">
                        <h5 className="form-heading">Guardian Information</h5>
                      </Col>
                    </Row>
                    <Row>

                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label className="form-label">{"Guardian Name"}</label>
                          <Input
                            type="text"
                            placeholder=" "
                            name="full_name"
                            className={`input-text-field`}
                            value={full_name}
                            onChange={onFormFieldChange}
                            maxLength={"20"}

                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <label className="form-label">
                          {"Relation"}
                        </label>
                        <Input
                          type="text"
                          placeholder=" "
                          name="relation"
                          className={`input-text-field`}
                          value={relation}
                          onChange={onFormFieldChange}
                          maxLength={"20"}

                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <label className="form-label">
                          {"Gurdian's Phone Number"}
                        </label>
                        <Input
                          type="text"
                          placeholder=" "
                          name="phone"
                          className={`input-text-field`}
                          value={phone}
                          onChange={onFormFieldChange}
                          maxLength={"20"}

                        />
                      </Col>
                      <Col className="pr-1" md="6">
                        <label className="form-label">
                          {"Address 1:"}
                        </label>
                        <Input
                          type="textarea"
                          placeholder=" "
                          rows="5"
                          name="address_1"
                          className={`input-text-field`}
                          value={address_1}
                          onChange={onFormFieldChange}
                          maxLength={"60"}

                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4} lg={3} sm={12} xs={12}>
                        <Button
                          className="mb-3 btn-block"
                          outline
                          color="primary"
                          onClick={() => this.handelGoBack()}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col lg={6} md={4} sm={12} xs={12} />
                      <Col lg={3} sm={12} md={4} xs={12}>
                        {this.props.isEdit ?
                          <Button
                            className="btn-round btn-block float-right  "
                            color="primary"
                            onClick={() => onSave()}
                            type="button"
                          >
                            Add Student
                          </Button>
                          :
                          <Button
                            className="btn-round btn-block float-right  "
                            color="primary"
                            onClick={onStudentUpdate}
                            type="button"
                          >
                            Update Student
                          </Button>
                        }

                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );


    let renderView = (
      <>
        {" "}
        {this.state.showModal ? (
          <CaptureImage
            isOpen={this.state.showModal}
            toggle={this.modeladd}
            passingImage={this.uploadImageToServer}
          />
        ) : (
          ""
        )}
        {template}
      </>
    );

    return renderView;
  }
}
const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
});


export default connect(null, actions)(withRouter(StudentForm));
